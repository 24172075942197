import { ref } from "vue";
import { useDocument } from "vuefire";
import { settingsRef } from "@/firebase.js";
import { doc } from "firebase/firestore";
import { SETTINGS_DOCUMENTS } from "@/constants/index.js";

const state = {
  account: ref(null),
  globalSettings: ref(null),
  theme: ref(null),
};

const pending = {
  account: ref(true),
  globalSettings: ref(true),
  theme: ref(true),
};

let initialized = false;

function setupDocument(key, documentPath) {
  const { pending: docPending } = useDocument(doc(settingsRef, documentPath), {
    target: state[key],
  });
  pending[key].value = docPending;
}

export function firestoreStore() {
  if (!initialized) {
    console.log("Initialising settings state.");
    setupDocument("account", SETTINGS_DOCUMENTS.ACCOUNT);
    setupDocument("globalSettings", SETTINGS_DOCUMENTS.GLOBAL_SETTINGS);
    setupDocument("theme", SETTINGS_DOCUMENTS.THEME);
    initialized = true;
  }

  return {
    state,
    pending,
  };
}
