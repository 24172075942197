const firebaseConfig = {
  projectId: "the-daily-1b9d9",
  appId: "1:901841995222:web:9819b4dd48c1c15656d077",
  databaseURL:
    "https://the-daily-1b9d9-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-daily-1b9d9.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyBEzVasaECnhsXVwWDZLX9dHYii0kj54i0",
  authDomain: "the-daily-1b9d9.firebaseapp.com",
  messagingSenderId: "901841995222",
  measurementId: "G-DFYVDFJSV9",
};

export default firebaseConfig;
